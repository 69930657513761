var hostname = $(location).attr('protocol') + '//' + $(location).attr('host');
var path = window.location.pathname.toLowerCase();
var initial = hostname + "/initial";
var web_auth = hostname + "/web/auth";
var web_api = hostname + "/web/api";
var isLoggedin = false;
var isAccountVerified = false;
var isEmailVerified = false;
var queryStringParams = '';
var userDataNotif = {};
var emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
var dataLayer = window.dataLayer = window.dataLayer || [];
var videoType = 3;
var isGetPlusActive = ($("meta[name='is_getplus_active']").attr("content") == 'true');
var is_getplus_earn_point_call_permitted = ($("meta[name='is_getplus_earn_point_call_permitted']").attr("content") == 'true');
var is_getplus_member_profile_call_permitted = ($("meta[name='is_getplus_member_profile_call_permitted']").attr("content") == 'true');
var is_getplus_register_member_call_permitted = ($("meta[name='is_getplus_register_member_call_permitted']").attr("content") == 'true');
var checkRegionLotame;
var googleClientId = $("meta[name='google-client-id']").attr("content");

$( document ).ready(function() {
  $('.lazy-imgs').lazyload();
   isLoggedin = !!$("meta[name='_sec_logged']").attr("content");
   if(isLoggedin) {
   } else {
     $('#burger-logout-border').addClass('collapse');
     $('#burger-logout').addClass('collapse');
   }
   setTimeout(function(){
     if(window.location.href && window.location.href.includes('twitter_user_not_registered')) {
       $('#login').parent().addClass('collapse');
       $('#register-message').parent().removeClass('collapse');
     }
   },50);
   queryStringParams = parseQueryString();
   fillImgSvg()
   setTimeout(function(){
    $('#credential_picker_iframe, #credential_picker_container').css('zIndex', '999999999999')
   }, 2000)
   
});
$( window ).on('load', function(){	
    setTimeout(function(){
        $('#credential_picker_iframe, #credential_picker_container').css('zIndex', '999999999999')
    }, 2000)	
})
var userData = {};
var loginVia;
$(function () {
    loginUserRestrict();
    $('#mobile-menu-login .fb, #menu-not-logged-in .fb,#mobile-menu-login .tw, #menu-not-logged-in .tw').click(function(event) {
      toggleMenuOverlay();
      var _cc14390_Add = [];
          _cc14390_Add.push({'type' : 'seg','value' : 'historia.id : Login Event'});
          addLotame(_cc14390_Add);
    });
    $('#login .fb, #login-landing .fb, #socmed-login .fb, #socmed-login-main .fb,#login .tw, #login-landing .tw, #socmed-login .tw, #register-socmed .register-tw,#socmed-login-main .tw').click(function(event) {
        $('#login').parent().addClass('collapse');
        var _cc14390_Add = [];
        _cc14390_Add.push({'type' : 'seg','value' : 'historia.id : Login Event'});
        addLotame(_cc14390_Add);
    });
    $('.icon-wt-view').click(function (event) {
        $(this).parent().find('input').attr('type', function (event, attr) {
            return attr == 'password' ? 'text' : 'password';
        });
    });
});

var webAuthRestrict = function(url, params, reqType) {
  var data = $.ajax({
      url: web_auth + url,
      type: reqType,
      dataType: 'json',
      data: $.param(params, true),
      beforeSend: function(request) {
          request.setRequestHeader('X-User-Agent', 'Web');
          request.setRequestHeader(
              $("meta[name='_csrf_header']").attr("content"),
              $("meta[name='_csrf']").attr("content"));
      }
  });
  return data;
};

var initialApiRestrict = function (url, params, reqType) {
    var data = $.ajax({
        url: initial + url,
        type: reqType,
        dataType: 'json',
        data: $.param(params, true),
        beforeSend: function (request) {
            request.setRequestHeader('X-User-Agent', 'Web');
        }
    });
    return data;
};

var actionWebApiRestrict = function (url, params, reqType, header) {
    var data = $.ajax({
        url: web_api + url,
        type: reqType,
        dataType: 'json',
        data: $.param(params, true),
        beforeSend: function (request) {
            request.setRequestHeader('Nav-User-Agent', navigator.userAgent);
            request.setRequestHeader('X-User-Agent', 'Web');
            request.setRequestHeader(
                $("meta[name='_csrf_header']").attr("content"),
                $("meta[name='_csrf']").attr("content"));

            if (header) {
                $.each(header, function (i, val) {
                    request.setRequestHeader(Object.keys(val)[0], val[Object.keys(val)[0]]);
                })
            }
        }
    });
    return data;
};

var actionSSEWebApiRestrict = function (url, params, reqType, header) {
    var payload;
    var requestHeaders = {},
        requestHeadersNames = {};
    var setRequestHeader = function(name, value) {
        name = requestHeadersNames[ name.toLowerCase() ] =
            requestHeadersNames[ name.toLowerCase() ] || name;
        requestHeaders[ name ] = value;
    }
    setRequestHeader('Nav-User-Agent', navigator.userAgent);
    setRequestHeader('X-User-Agent', 'Web');
    setRequestHeader(
        $("meta[name='_csrf_header']").attr("content"),
        $("meta[name='_csrf']").attr("content"));
    if (reqType == "POST") {
        if (params instanceof FormData) {
            payload = params;
        } else {
            payload = jQuery.param(params);
            setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        }
    }
    if (reqType == "GET" && params && params.trim != "") {
        url = url + "?" + jQuery.param(params);
    }
    if (header) {
        $.each(header, function (i, val) {
            setRequestHeader(Object.keys(val)[0], val[Object.keys(val)[0]]);
        });
    }

    var source = new SSE(web_api + url, {headers: requestHeaders,
        payload: payload,
        method: reqType});
    return source;
};

function sendLotameDemographic(dob, gender) {

    if (gender == 1)
        gender = 'male';
    else
        gender = 'female';

    dob = new Date(dob);
    var today = new Date();
    var age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
    var _cc14390_Add = [];
    _cc14390_Add.push({'type': 'dem', 'value': 'historia.id : Demographic Age : ' + age});
    _cc14390_Add.push({'type': 'dem', 'value': 'historia.id : Demographic Gender : ' + gender});
    _cc14390_Add.push({'type': 'seg', 'value': 'historia.id : Logged In Users'});
    addLotame(_cc14390_Add)

}

var profileImageUrl = '';
var completenessPercentage = '';

var loginHandler = function (json) {
    if (json.status == "600") {
        setUserAuthenticated(true);//refresh cookie expires
        userData = json.data;

        if (localStorage.getItem('username') == null) {
            window.addEventListener('lotame_loaded', function () {
                sendLotameDemographic(userData.birthday, userData.gender)
            });
        }

        if (userData.activation == 1 && userData.email_verified == 1) {
            isAccountVerified = true;
        }

        $('#user-notif').removeClass('collapse').addClass('user-notif-display-flex');
        $('#user-notif2').removeClass('collapse').addClass('user-notif-display-flex');
        $('.navigation-profile-container').removeClass('collapse');
        $('#notifIndicator-mobile-container').removeClass('collapse');
        $('#mobile-header-separator').removeClass('collapse');
        //insert self picture to top menu
        $('#user-profil').removeClass('collapse').html('<img alt="user profile" class="no-mobile user-profil-image"  src="' + userData.picture.small + '" onerror="this.src=\'https://d220hvstrn183r.cloudfront.net/profile_picture/default-profpic.large\'">');
        $('#leaderboard-profile-picture').attr('src', userData.picture.medium).attr('onerror', 'this.src=\'https://d220hvstrn183r.cloudfront.net/profile_picture/default-profpic.large\'');

        //add edit button to profile page if viewing self
        if ($('#edit-profile-button').attr('data-id') == userData.username) {
            $('#edit-profile-button').find('div').attr('onclick', 'window.location="/@' + userData.username + '/edit"');
            $('#edit-profile-button').removeClass('hide')
        } else {
            $('#edit-profile-button').remove()
        }

        localStorage.setItem('username', userData.username);

        //add url
        $('.user-dd #view-profil').prop('href', '/@' + userData.username);
        $('.user-dd #setting').prop('href', '/@' + userData.username + '/settings');
        $('.user-dd #undang-teman').prop('href', '/@' + userData.username + '/invite-friend');
        $('#burger-href-edit-profile').prop('href', '/@' + userData.username + '/edit');
        $('#burger-href-edit-password').prop('href', '/@' + userData.username + '/edit-password');
        $('#burger-href-add-category').prop('href', '/@' + userData.username + '/add-category');
        $('#burger-href-invite-friend').prop('href', '/@' + userData.username + '/invite-friend');
        $('#menu-pengaturan').prop('href', '/@' + userData.username + '/settings');
        $('#completeness-bar-text').attr('onclick', 'window.location="/@' + userData.username + '/edit"');

        //campaign info
        if (userData.grand_campaign && userData.grand_campaign.is_campaign_opt_in == false) {
            $('#quiz-campaign').html('<img class="no-mobile" onclick="window.location=\'/leaderboard-TFBcvdWdE7yY9pnSayLVBjf5\'" src="/web/assets/img/gamification/join_campaign_web.gif" alt="join campaign"><img onclick="window.location=\'/leaderboard-TFBcvdWdE7yY9pnSayLVBjf5\'" class="no-web" src="/web/assets/img/gamification/join_campaign_mweb.gif" alt="join campaign">')
        }

        //set completeness bar
        completenessPercentage = userData.profile_completion.completion;
        setCompletenessPercentageBar();
        $('#completeness-bar-point').html(userData.profile_completion.reward);
        //show verification bar if user havent verified
        if (userData.email_verified == 0) {
            $('#verification-bar').removeClass('hide').addClass('show').parent().removeClass('hide').addClass('show');
            if ($('#verification-bar').length > 0) {
                $('#top-content-space').addClass('notified')
            }

        } else { //already verified
            //show completion bar if user haevent complete
            if (userData.profile_completion.completion < 100) {
                $('#completeness-bar').removeClass('hide').addClass('show').parent().removeClass('hide').addClass('show');
                if ($('#completeness-bar').length > 0) {
                    $('#top-content-space').addClass('notified')
                }
            }
        }

        //set profile image to conversation box
        $('#comment-profpic, .create-lounge-profpic, .comment-expert-answer-profpic,.comment-reply-profpic').attr('src', userData.picture.small).attr('onerror', 'this.src=\'https://d220hvstrn183r.cloudfront.net/profile_picture/default-profpic.large\'');

        $('#menu-not-logged-in').removeClass('animated').addClass('collapse');
        $('#menu-logged-in').removeClass('collapse').addClass('animated');
        var appendText = '';
        appendText += '<div style="h-50">';
        appendText += '<a href="/@' + userData.username + '">';
        appendText += '<img alt="user profile" class="banner-profile-image"  src="' + userData.picture.small + '" onerror="this.src=\'https://d220hvstrn183r.cloudfront.net/profile_picture/default-profpic.large\'">';
        appendText += '<span class="banner-profile-username" >' + userData.username + '</span>';
        appendText += '</a>';
        appendText += '</div>';
        $('#menu-hr').css("margin-top", 0);
        $('#logged-in-profile').removeClass('collapse').html(appendText);
        actionWebApiRestrict('/v1/notification/unshow', {}, 'GET').done(function (json) {
            if (json.status == "600") {
                var userNotifCount = json.data;
                if (userNotifCount > 0) {
                    $('#user-notif-count').html(userNotifCount);
                    $('#user-notif-count2').html(userNotifCount);
                    $('#user-notif-count').removeClass('collapse');
                    $('#user-notif-count2').removeClass('collapse');
                    $('#notifIndicator, #notifIndicator-mobile').removeClass('collapse')
                } else {
                    $('#user-notif-count').addClass('collapse');
                    $('#user-notif-count2').addClass('collapse');
                    $('#notifIndicator, #notifIndicator-mobile').addClass('collapse')
                }
            }
        })
    } else {
        setUserAuthenticated(false);
        $('#open-login').removeClass('collapse');

    }
}
var usernameAfterLogin,
hashReadArticle,
scrolled = 'false',
isGetPlusJoined = false,
hashCommentTotal;
var jsonConsumer = '';
var loginUserRestrict = function () {
    if (isLoggedin) {
        $('.overlay').remove();
        if(isGetPlusActive){
            var source = actionSSEWebApiRestrict('/v1/me/info_with_rewards', '', 'GET');
            source.addEventListener('message', function (e) {
                var json = JSON.parse(e.data)
                if (e.id == "consumer") {
                    usernameAfterLogin = json.data.id;
                    loginHandler(json);
                    jsonConsumer = json;
                }
                if (e.id == "daily_login_point" || e.id == "profile_completion_reward" || e.id == "register_reward") {
                    if(json.status == "703") {
                      
                    }else if(json.status == "803"){
                        
                    }else{
                        isGetPlusJoined = true;
                    }
                    if(isGetPlusJoined){
                        drawButtonJoin()
                        changeUrlProfileGetplus(jsonConsumer)
                        if(typeof json.data.points_balance !== "undefined") {
                            if(is_getplus_earn_point_call_permitted) refreshPoitGetplus(json);
                        }
                        if(is_getplus_earn_point_call_permitted){
                            if($('#article-content').length){
                                var dateRequest = $('#article-content').attr('data-curent_date');
                                hashCommentTotal = usernameAfterLogin+'-TC-'+dateRequest;
                                hashReadArticle = usernameAfterLogin+'-'+articleId+'-'+dateRequest;
                                scrolled = $.cookie(hashReadArticle);
                                if($.cookie(hashReadArticle) === null){
                                    $.cookie(hashReadArticle, true, { expires: 1 });
                                    scrolled = 'true';
                                }
                                if($.cookie(hashCommentTotal) === null){
                                    $.cookie(hashCommentTotal, 0, { expires: 1 , path: '/'});
                                }else{
                                    $.cookie(hashCommentTotal, $.cookie(hashCommentTotal), { expires: 1 , path: '/'});
                                }
                                scrollPageGetPlus()
                            }
                        }
                    }
                }
            });
            source.stream();
        }else{
            actionWebApiRestrict('/v1/me/info', {}, 'GET').done(function (json) {
                if (json.status == "600") {
                    loginHandler(json);
                }
            })
        }
        
    } else {
        localStorage.removeItem('username');
        $('#menu-logged-in').removeClass('animated').addClass('collapse');
        $('#menu-not-logged-in').removeClass('collapse').addClass('animated');
        $('#menu-hr').css("margin-top", 60);
        $('#open-login').removeClass('collapse');
        $('#user-notif').addClass('collapse').removeClass('user-notif-display-flex');
        $('#user-notif2').addClass('collapse').removeClass('user-notif-display-flex');
        $('#mobile-header-separator').addClass('collapse')
        // Google One Tap Sign In
        if (typeof googleClientId !== 'undefined' && !path.startsWith("/login") && !path.startsWith("/register")) {
            google.accounts.id.initialize({
                client_id: googleClientId,
                callback: handleGoogleCredentialResponse,
                auto_select: false
            });
            google.accounts.id.prompt();
        }
    }
};

var handleGoogleCredentialResponse = function (data) {
        dataLayer.push({
        'event': 'usersession',
        'sessionactivity': 'Login',
        'sessionmedium': 'Google_One_Tap'
    });
    var $form = $('<form>', {
        action: hostname + '/signin/google-1-tap',
        method: 'post'
    });
    var fields = {
        credential: data.credential,
        redirect_url: window.location.href
    }
    $.each(fields, function(key, val) {
        $('<input>').attr({
            type: "hidden",
            name: key,
            value: val
        }).appendTo($form);
    });
    $form.appendTo('body').submit();
};
var handleGoogleCredentialResponse = function (data) {
        dataLayer.push({
        'event': 'usersession',
        'sessionactivity': 'Login',
        'sessionmedium': 'Google_One_Tap'
    });
    var $form = $('<form>', {
        action: hostname + '/signin/google-1-tap',
        method: 'post'
    });
    var fields = {
        credential: data.credential,
        redirect_url: window.location.href
    }
    $.each(fields, function(key, val) {
        $('<input>').attr({
            type: "hidden",
            name: key,
            value: val
        }).appendTo($form);
    });
    $form.appendTo('body').submit();
};
var setCompletenessPercentageBar = function () {
    if ($('form#setting').attr('data-completion')) {
        completenessPercentage = $('form#setting').attr('data-completion')
    }

    var winWidth, greyPos, greyWidth;
    winWidth = $(window).width();
    if (winWidth > 1200) {
        greyPos = ((winWidth - 1200) / 2);
        greyWidth = (((100 - completenessPercentage) / 100) * 1200) + greyPos;
        greyPos = 0
    } else {
        greyPos = 0;
        greyWidth = ((100 - completenessPercentage) / 100) * winWidth
    }
    $('#completeness-bar').find('.grey').css('right', greyPos);
    $('#completeness-bar').find('.grey').css('width', greyWidth)
};

function changeTimeFormat(unixTime) {
    var m = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'];
    var nd = new Date(parseInt(unixTime)); //published date
    var cd = new Date(); //now date
    var newdt = '';
    var inDays = function (d2, d1) {
        return Math.round((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24))
    };

    var dateString = nd.getDate() + " " + m[nd.getMonth()] + " " + nd.getFullYear() + ", ";
    var timeString = (nd.getHours() < 10 ? "0" + nd.getHours() : nd.getHours()) + ":" +
        (nd.getMinutes() < 10 ? "0" + nd.getMinutes() : nd.getMinutes());
    var diffDays = inDays(cd, nd);

    if (diffDays == 0) {
        if ((cd.getDate() > nd.getDate()) || (cd.getMonth() > nd.getMonth()) || (cd.getFullYear() > nd.getFullYear())) {
            newdt = "Kemarin, " + timeString
        } else {
            newdt = "Hari ini, " + timeString;
        }
    } else if (diffDays == 1) {
        newdt = "Kemarin, " + timeString;
    } else if (diffDays < 7) {
        newdt = diffDays + " hari lalu, " + timeString;
    } else if (diffDays < 30) {
        newdt = Math.floor(diffDays / 7) + " minggu lalu, " + timeString;
    } else if (diffDays == 30) {
        newdt = "1 Bulan lalu";
    } else {
        newdt = dateString + timeString;
    }
    return newdt;
}

function goLoungeDetail(event, target) {
    if ($(event.target).closest('.newchat-action').length) {

    } else {
        window.location = target;
    }
}

var loungeStreamLikeCallStatus = true;

function likeLounge(elmt) {

    if (isLoggedin) {
        if (isAccountVerified) {
            var param = {
                conversation_id: $(elmt).attr('data-id')
            };
            if (loungeStreamLikeCallStatus) {
                loungeStreamLikeCallStatus = false;
                actionWebApiRestrict('/v1/conversation/' + $(elmt).attr('type'), param, 'POST').done(function (json) {
                    loungeStreamLikeCallStatus = true;
                    if (json.status == 600) {
                        var _cc14390_Add = [];
                        _cc14390_Add.push({'type': 'seg', 'value': 'historia.id : Like'});
                        addLotame(_cc14390_Add);

                        if ($(elmt).attr('type') == 'like') {
                            $(elmt).find('img').attr('src', '/web/assets/icon/bookmark/liked.svg');
                            $(elmt).attr('type', 'cancellike');
                            $(elmt).find('.like-number').text(parseInt(parseInt($(elmt).find('.like-number').first().text()) + 1))
                        } else {
                            $(elmt).find('img').attr('src', '/web/assets/icon/bookmark/like.svg');
                            $(elmt).attr('type', 'like');
                            $(elmt).find('.like-number').text(parseInt(parseInt($(elmt).find('.like-number').first().text()) - 1))
                        }
                    }
                });
            }
        } else {
            $('#overlay-verify-email').removeClass('collapse');
        }
    } else {
        showLoginOverlay();
    }
}

function likeArticle(elmt) {
  if (isLoggedin) {
    var param = {
      article_id: $(elmt).attr('data-id')
    };
    if(loungeStreamLikeCallStatus) {
      loungeStreamLikeCallStatus = false;
      dataLayer.push({
        event: 'other_article_like'
      });
      actionWebApiRestrict('/v1/article/'+$(elmt).attr('type'), param, 'POST').done(function(json){
        loungeStreamLikeCallStatus = true;
        if(json.status == 600){
            var _cc14390_Add = [];
              _cc14390_Add.push({'type' : 'seg', 'value' : 'historia.id : Like'});
              addLotame(_cc14390_Add);
          if($(elmt).attr('data-type') == 'like') {
            $(elmt).attr('src','/web/assets/icon/bookmark/liked.svg');
            $(elmt).attr('data-type','cancellike').find('span').text('LIKED!');
            $(elmt).next().text( parseInt( parseInt($(elmt).next().text()) +1) );
          } else {
            $(elmt).attr('src','/web/assets/icon/bookmark/like.svg');
            $(elmt).attr('data-type','like').find('span').text('LIKE');
            $(elmt).next().text( parseInt(parseInt($(elmt).next().text())-1) );
          }
        }
        });
    } else {
        showLoginOverlay();
    }
    }
}

function bookmarkArticle(elmt) {
  if (isLoggedin) {
    var param = {
      article_id: $(elmt).attr('data-id')
    };
    if(loungeStreamLikeCallStatus) {
      loungeStreamLikeCallStatus = false;
      dataLayer.push({
        event: 'other_article_bookmark'
      });
      actionWebApiRestrict('/v1/article/bookmark/'+$(elmt).attr('type'), param, 'POST').done(function(json){
        loungeStreamLikeCallStatus = true;
        if(json.status == 600){
          if($(elmt).attr('data-type') == 'add') {
            $(elmt).attr('src','/web/assets/icon/bookmark/booked.svg');
            $(elmt).attr('data-type','delete')
          } else {
            $(elmt).attr('src','/web/assets/icon/bookmark/bookmark.svg');
            $(elmt).attr('data-type','add')
          }
        }
      })
    } else {
        showLoginOverlay();
    }
  }
}

function changePinnacleDateFormat(timestamp) {
    var uTime = new Date(parseInt(timestamp));
    var fTime = uTime.getDate() - 10 < 0 ? '0' + uTime.getDate() : uTime.getDate();
    fTime += '-';
    uTime.getMonth() - 10 < 0 ? fTime += '0' + uTime.getMonth() : fTime += uTime.getMonth();
    fTime += '-' + uTime.getFullYear() + ' ';
    uTime.getHours() - 10 < 0 ? fTime += '0' + uTime.getHours() : fTime += uTime.getHours();
    fTime += '.';
    uTime.getMinutes() - 10 < 0 ? fTime += '0' + uTime.getMinutes() : fTime += uTime.getMinutes();
    return fTime
}

function generateArticleGridItem(value, from, type) {
    var appendText = '';
    appendText += '<div class="grid-item';
    if (type == 'bookmark') {
        appendText += '-bookmark'
    }
    appendText += '" >';
    if (from == 'home') {
        value.url += '?page_source=home'
    }
    appendText += '<div class="hoverimage" onclick="return goLoungeDetail(event,\'' + value.url + '\')">';
    appendText += '<a href="' + value.url + '">';
    appendText += '<img alt="cover ' + value.title + '" ';
    value.cover ? appendText += 'src="' + value.cover.small + '"' : appendText += '';
    appendText += '  class="grid-item-cover-image">';
    appendText += '</a>';
    appendText += '<div class="grid-item-desc">';

    appendText += '<div class="grid-item-category grid-item-category-text m-top-5">';
    appendText += '<div style="font-family: \'Open Sans\',sans-serif; padding-left: 5px; color: #333333; border-left: 2px solid red" class="bold" onclick="return goTopic(\'' + slugify(value.category.name) + '\')">' + value.category.name.toUpperCase() + '</div>';
    appendText += '</div>';
    appendText += '<a href="' + value.url + '" class="grid-item-title font-libre" style="color: #000!important">' + value.title + '</br>' + '</a>';
    if (value.taicing) {
        appendText += '<a href="' + value.url + '" class="grid-item-taicing" style="color: #666!important">' + value.taicing + '</br>' + '</a>';
    }
    appendText += '<div  class="grid-item-info newchat-action">';
    appendText += '<div class="article-status-stream bold clickable">';
    appendText += '<span class="m-bottom-10">';
    if (value.like_status == 1) {
        appendText += '<img alt="cancel like" class="m-right-5 icon-like" src="/web/assets/icon/bookmark/liked.svg" data-id="' + value.id + '" onclick="likeArticle(this)" data-type="cancellike">'
    } else {
        appendText += '<img alt="like" class="m-right-5 icon-like" src="/web/assets/icon/bookmark/like.svg" data-id="' + value.id + '" onclick="likeArticle(this)" data-type="like">'
    }
    appendText += '<span class="tag-like-text" style="padding-right: 5px;">' + value.total_like + '</span>';
    appendText += '</span>';
    appendText += '</div>';
    appendText += '<div class="article-status-stream clickable">';
    appendText += '<img class="clickable m-right-5" src="/web/assets/icon/bookmark/comment.svg" alt="comment">';
    appendText += '<span class="tag-like-text" style="padding-right: 5px;">' + value.total_comment + '</span>';
    appendText += '</div>';
    appendText += '<div class="pull-right">';
    if (!value.is_bookmarked) {
        appendText += '<img alt="add" class="clickable" src="/web/assets/icon/bookmark/bookmark.svg" data-id="' + value.id + '" onclick="bookmarkArticle(this)" type="add">'
    } else {
        appendText += '<img alt="delete" class="clickable" src="/web/assets/icon/bookmark/booked.svg" data-id="' + value.id + '" onclick="bookmarkArticle(this)" type="delete">'
    }
    appendText += '</div>';

    if (value.sponsor_type) {
        appendText += '<div class="grid-item-article-tag">';
        appendText += value.sponsor_type;
        appendText += '</div>'
    }

    appendText += '</div>';
    appendText += '</div>';
    appendText += '</div>';
    appendText += '</div>';
    return appendText;
}

window.fbAsyncInit = function() {
    FB.init({
        appId: '572326363105209',
        // cookie: true,
        xfbml: true,
        version: 'v6.0'
    });
};
(function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

function logoutFb(){
  FB.logout();
}

var setUserAuthenticated = function (authenticated) {
    if (authenticated) {
        var sevenDaysFromNow = new Date();
        sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
        //Cookies.set('authenticated', true, { expires: sevenDaysFromNow });
        $.cookie('authenticated', true, {expires: sevenDaysFromNow});
    } else {
        //Cookies.remove('authenticated');
        $.removeCookie('authenticated');
    }
};

function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

function debounce(fn, delay) {
    var timer = null;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

function generateSearchResult(json, param) {
  var appendText = '';
  appendText += '<div class="" style="padding: 10px 20px; color: #ccc; font-size: 0.9em">';
    appendText += 'Hasil Pencarian Artikel ('+json.data.total_articles+')';
    if(json.data.total_articles > 0){
      appendText += '<span><a style="color: #2adbab; padding: 0" href="/search/'+param.keyword+'/1"> see more </a></span>'
    }
    appendText += '</div>';
    json.data.articles.forEach(function (data) {
        appendText += '<div class="search-all-article" style="">';
        appendText += '<a style="padding: 0; color: #4c4c4c!important" href="' + data.url + '">';
        appendText += data.title;
      appendText += '</a>';
    appendText += '</div>'
  });
  appendText += '<hr class="topic-hr">';
  appendText += '<div class="" style="padding: 10px 20px; color: #ccc; font-size: 0.9em">';
    appendText += 'Hasil Pencarian Pembicaraan ('+json.data.total_conversations+')';
    if(json.data.total_conversations > 0){
      appendText += '<span><a style="color: #2adbab; padding: 0" href="/search/'+param.keyword+'/1"> see more </a></span>'

    }
    appendText += '</div>';
    json.data.consumers.forEach(function (data) {
        appendText += '<div style="padding: 3px 20px;">';
        appendText += '<div style="float: left; width: 50px">';
        appendText += '<img src="' + data.picture.small + '" style="width: 40px; height: 40px; border-radius: 4px" alt="' + data.picture.small + '" />';
        appendText += '</div>';
        appendText += '<div style="float: left; width: 80%">';
        var noTitleStyle = '';
        if (!data.title) {
            noTitleStyle = 'line-height: 35px;'
        }
        appendText += '<a style="color: #2adbab; padding: 0; ' + noTitleStyle + '" href="/@' + data.username + '">';
        appendText += data.username;
        if (data.role.name == 'ROLE_EXPERT') {
            appendText += '<span aria-hidden="true" data-fonticon="&#xe001;" class="fonticon-expert" style="color: #58aeeb;font-size: 2em;line-height: 0;position: relative;top: 7px;left: -7px;"></span>'
        }
        if (data.is_certified == true) {
            appendText += '<img src="/web/assets/icon/profile/icon-certified.svg" style="width: 15px; height: 16px; margin-top: 10px" alt="certified"/>'
        }
        appendText += '</a>';
        if (data.title) {
            appendText += '<div style="color: #ccc; font-size: 0.8em">' + data.title + '</div>'
        }
        appendText += '</div>';
        appendText += '<div class="clearfix"></div>';
        appendText += '</div>'
    });
    appendText += '<div style="height: 10px"></div>';
    return appendText;
}

//in article detail, conv detail, conv stream
function getCursorPosition(element) {
    var el = $(element).get(0);
    var pos = 0;
    if ('selectionStart' in el) {
        pos = el.selectionStart;
    } else if ('selection' in document) {
        el.focus();
        var Sel = document.selection.createRange();
        var SelLength = document.selection.createRange().text.length;
        Sel.moveStart('character', -el.value.length);
        pos = Sel.text.length - SelLength;
    }
    return pos;
}

function getCaretPosition(ctrl) {
    var start, end;
    if (ctrl.setSelectionRange) {
        start = ctrl.selectionStart;
        end = ctrl.selectionEnd;
    } else if (document.selection && document.selection.createRange) {
        var range = document.selection.createRange();
        start = 0 - range.duplicate().moveStart('character', -100000);
        end = start + range.text.length;
    }
    return {
        start: start,
        end: end
    }
}

function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last,
        deferTimer;
    return function () {
        var context = scope || this;

        var now = +new Date,
            args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold + last - now);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

var parseQueryString = function () {
    var str = window.location.search;
    var objURL = {};
    str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
            objURL[$1] = $3;
        }
    );
    return objURL;
};

$.fn.scrollView = function () {
    return this.each(function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top - $(window).height()
        }, 1000);
    });
};

//article detail, conv detail
function doLinkify(originalContent) {
    var mentionRegex = /(?:^|\s)(@[\w.\-]{3,25})/g;
    var mentionMatches = originalContent.replace('<p>', '').match(mentionRegex);
    if (mentionMatches) {
        $.each(mentionMatches, function (i, val) {
            if (val) {
                var origVal = val;
                var whiteSpace = isWhiteSpaceNeeded(origVal);
                var regex = new RegExp(origVal, '');
                originalContent = originalContent.replace(regex, whiteSpace + '<a class="bold" href="/' + origVal.trim() + '" target="_blank" style="color: #333333!important">' + origVal.trim() + '</a>')
            }
        });
    }
    var urlRegex = /(?:^|\s)[\w:\/.]*historia.id([\w.\-\/]*)/g;
    var urlMatches = originalContent.replace('<p>', '').match(urlRegex);
    if (urlMatches) {
        $.each(urlMatches, function (i, val) {
            if (val) {
                var origVal = val;
                var destination = getDestination(origVal);
                var whiteSpace = isWhiteSpaceNeeded(origVal);
                var regex = new RegExp(origVal, '');
                originalContent = originalContent.replace(regex, whiteSpace + '<a style="text-decoration: underline;" href="' + destination + '" target="_blank" >' + origVal.trim() + '</a>')
            }
        })
    }
    return originalContent;
}

function getDestination(url) {
    var pathNameRegex = /historia.id([\w.\/\-]*)/;
    var destination = pathNameRegex.exec(url)[1];
    if (!destination) {
        return '/'
    } else {
        return destination
    }
}

function isWhiteSpaceNeeded(text) {
    if (text.charAt(0) == ' ') {
        return ' '
    } else {
        return ''
    }
}

function convCategory() {
    if (isMobile) {
        window.location = "/category/lounges"
    }
}

function saveLogPlayPodcast(episodeId) {
    var param = {'episode_id': episodeId};
    actionWebApiRestrict("/v1/podcast/episode", param, 'POST').done(function (json) {
        if (json.status != 600) {
            throw "Something wrong!";
        }
    });
}

function sliceString(text, totalLimit) {
    return text.slice(0, totalLimit) + '...';
}

var postApiWithJsonType = function (url, params, reqType, header) {
    var data = $.ajax({
        url: web_api + url,
        type: reqType,
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify(params),
        beforeSend: function (request) {
            request.setRequestHeader('X-User-Agent', 'Web');
            request.setRequestHeader(
                $("meta[name='_csrf_header']").attr("content"),
                $("meta[name='_csrf']").attr("content"));

            if (header) {
                $.each(header, function (i, val) {
                    request.setRequestHeader(Object.keys(val)[0], val[Object.keys(val)[0]]);
                })
            }
        }
    });
    return data;
};

function fillImgSvg() {
    jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
}

function drawListArticle(value, position) {
    var append = '';
    if(isMuseumActive){
      switch (position) {
        case 1:
            setClass = ' next-list-large';
            if ($(window).width() > 800) {
                setLargeImage = value.cover.original
            } else {
                setLargeImage = value.cover.medium
            }

            break;
        default:
            setClass = '';
            setLargeImage = value.cover.medium
      }
      if(position == 4){
        append += '<a href="/historipedia/museum" class="next-latest-list next-list-large link-museum"><img src="https://d220hvstrn183r.cloudfront.net/museum/cover-museum-ads-medium.jpg" alt="Historipedia Museum"></a>'
      }
    }else{
      switch (position) {
        case 1:
        case 4:
        case 7:
            setClass = ' next-list-large';
            if ($(window).width() > 800) {
                setLargeImage = value.cover.original
            } else {
                setLargeImage = value.cover.medium
            }

            break;
        default:
            setClass = '';
            setLargeImage = value.cover.medium
      }
    }
    

    var lengthTitle = value.title.length;
    var setTitle = '';
    if (lengthTitle > 100) {
        setTitle = htmlSubstring(value.richtext_title, 100) + '...'
    } else {
        setTitle = value.richtext_title
    }
    if (position == 7) {
        append += '<div id="Middleboard" class="text-center home-leaderboard"></div>'
    }
    append += '<a class="next-latest-list' + setClass + '" href="' + value.url + '" data-type="' + value.type + '">';
    append += '  <span class="latest-list-image">';
    if (value.type == videoType) {
        append += '<span class="button-video__play" title="Video ' + value.category.name + '"><img class="lazy-list" data-original="/web/assets/img/play_button.png" alt=">" src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="></span>';
    }
    append += '<img src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=" class="lazy-list" data-original="' + setLargeImage + '" alt="cover image"></span>';
    append += '  <span class="latest-list-detail">';
    if (value.category.name == 'HISTORIOGRAFIS') {
        if (setClass != ' next-list-large') {
            append += '    <span class="latest-list-category"><img class="lazy-list" data-original="/web/assets/img/historiografis.jpg" alt="Historiografis" src="data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs="></span>';
        } else {
            append += '    <span class="latest-list-category">' + value.category.name.toUpperCase() + '</span>';
        }
    } else {
        append += '    <span class="latest-list-category">' + value.category.name.toUpperCase() + '</span>';
    }
    append += '    <span class="latest-list-title"><h3>' + setTitle + '</h3></span>';
    if (value.match == '' || typeof value.match == 'undefined') {
        if (typeof value.taicing != 'undefined' || value.taicing == '') {
            append += '    <span class="latest-list-description"><p>' + trimText(value.taicing, 200, false) + '</p></span>';
        } else {
            append += '    <span class="latest-list-description"><p>' + trimText(value.plain_content, 200, true) + '</p></span>';
        }
    } else {
        if (value.match) {
            append += '    <span class="latest-list-description"><p>...' + value.match + '...</p></span>';
        }
    }

    append += '  </span>';
    append += '</a>';
    return append;
}

function trimText(text, long, isPlainText) {
    var trimsTexts = '';
    var addDotText = '';
    if (text) {
        var lengthText = text.length;
        trimsTexts = text.substring(0, long);
        if (isPlainText) {
            addDotText = '';
        } else {
            if (lengthText > 190) {
                addDotText = '...';
            }
        }
    }
    return trimsTexts + addDotText;
}

function showSuccessMessage(word) {
    $('#profile-update-message').stop().html(word).addClass('success').removeClass('failure').fadeIn('slow', function () {
        setTimeout(function () {
            $('#profile-update-message').fadeOut(5000)
        }, 100);
    }).queue('false')
}

function showFailureMessage(word) {
    $('#profile-update-message').stop().html(word).addClass('failure').removeClass('success').fadeIn('slow', function () {
        setTimeout(function () {
            $('#profile-update-message').fadeOut(5000)
        }, 100);
    }).queue('false')
}

function showVerificationPopUp() {
    $('#verification_code').val('');
    $('#verify-email-message').html('');
    $('#overlay-verify-email').removeClass('collapse')
}

function closeVerifyBar() {
    $('#verification-bar').parent().addClass('hide').removeClass('show')
}

(function () {
    var eventThrottle;
    $(window).on("load resize", function () {
        clearTimeout(eventThrottle);
        eventThrottle = setTimeout(setCompletenessPercentageBar, 100);
    });
})();

function closeCompletenessBar() {
    $('#completeness-bar').parent().addClass('hide').removeClass('show')
}

function setXPos() {
    if ($(window).width() > 1200) {
        $('.profile-update-message-close').each(function (i, obj) {
            $(this).css('right', (($(window).width() - 1200) / 2) + 8)
        })
    } else {
        $('.profile-update-message-close').each(function (i, obj) {
            $(this).css('right', '8px')
        })
    }
}

var pushFollowProfile = function (account, url) {
    dataLayer.push({
        'event': 'followprofile',
        'profileaccount': account,
        'profileurl': url
    });
};

$(window).on("resize", function () {
    setXPos()
});
setXPos();

function htmlSubstring(s, n) {
    var m, r = /<([^>\s]*)[^>]*>/g,
        stack = [],
        lasti = 0,
        result = '';

    //for each tag, while we don't have enough characters
    while ((m = r.exec(s)) && n) {
        //get the text substring between the last tag and this one
        var temp = s.substring(lasti, m.index).substr(0, n);
        //append to the result and count the number of characters added
        result += temp;
        n -= temp.length;
        lasti = r.lastIndex;

        if (n) {
            result += m[0];
            if (m[1].indexOf('/') === 0) {
                //if this is a closing tag, than pop the stack (does not account for bad html)
                stack.pop();
            } else if (m[1].lastIndexOf('/') !== m[1].length - 1) {
                //if this is not a self closing tag than push it in the stack
                stack.push(m[1]);
            }
        }
    }

    //add the remainder of the string, if needed (there are no more tags in here)
    result += s.substr(lasti, n);

    //fix the unclosed tags
    while (stack.length) {
        result += '</' + stack.pop() + '>';
    }

    return result;

}

const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

function escapeHtml(string) {
    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
        return entityMap[s];
    });
}

var addLotame = function(segment){
    if(typeof checkRegionLotame !== 'undefined') {
        sendLotameIfAllowed(segment);
    } else {
        window.addEventListener('check_region_lotame_loaded', function() {
            sendLotameIfAllowed(segment);
        });
    }
};

var sendLotameIfAllowed = function (segment) {
    if(checkRegionLotame){ //checkRegionLotame --> value from GTM
        if(!!window._cc14390) {
            sendLotameSegment(segment)
        } else {
            window.addEventListener('lotame_loaded', function() {
                sendLotameSegment(segment)
            });
        }
    }
};

function sendLotameSegment(segment){
    segment.forEach(element => _cc14390.add(element.type, element.value));
    _cc14390.bcp();
}
